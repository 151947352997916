import { useTranslation } from 'react-i18next';
import './i18n'; // Import the i18n configuration
import { TFunction } from 'i18next'; 
import { useMemo, useEffect, useState } from 'react';
import axios from 'axios';

const UserProfileLink = () => {

  const { t } = useTranslation();
  const [username, setUsername] = useState('');

  const [indexLanguage, setIndexLanguage] = useState('');

  const currentHostname = window.location.href;
  const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
  const linkUrl = currentHostname.includes(`${stagingKey}`) 
    ? `https://${stagingKey}.peaxel.me` 
    : 'https://peaxel.me';

  useEffect(() => {
    // Get the language from localStorage when the component mounts
    const storedLanguage = localStorage.getItem('pxlLanguage') || 'en'; // Default to 'en' if not found
    setIndexLanguage(storedLanguage);
  }, []); // Empty dependency array ensures this runs once on mount

  useEffect(() => {
    // Step 1: Get Wallet Address from Local Storage
    const walletAddress = localStorage.getItem('walletAddress');

    // Step 2: Call the API to Get Username using Axios
    const getUserData = async () => {
      try {
        if (walletAddress) {
          const apiUrl = `${linkUrl}/wp-json/custom/v1/get_user_by_metadata?value=${walletAddress}`;
     const response = await axios.get(apiUrl);

          // Assuming the API response contains the username field
          setUsername(response.data);
        }
      } catch (error) {
        console.error('Error fetching username:', error);
      }
    };

    getUserData();
  }, [/* Add any dependencies here if needed */]);

  //console.log('user profile', username);
  // Step 3: Build the Profile Link
  const profileLink = username ? `${linkUrl}/profile/?${username}/about` : '';

  return (
    <span>
      <a href={profileLink} target="_blank" rel="noopener noreferrer">
        {t('settings')}
      </a>
    </span>
  );
};

export default UserProfileLink;
